<template>
	<v-card flat v-if="initialized">
		<v-card-title>
			Sıkça Sorulan Sorular<v-spacer></v-spacer>
			<v-btn depressed class="text-capitalize" @click="add">
				<v-icon left x-small v-text="'fas fa-plus'"></v-icon>
				yeni
			</v-btn>
		</v-card-title>
		<v-card-text>
			<v-list dense>
				<v-list-item dense class="px-0 v-i" v-if="cmsFaq.length === 0">
					<v-list-item-content class="justify-center text-center">
						Henüz bir soru cevap eklenmemiş.
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense class="px-0" v-for="(faq, index) in $v.cmsFaq.$each.$iter" :key="`faq-${index}`">
					<v-list-item-content class="d-flex flex-row">
						<v-row>
							<v-col cols="12" md="6">
								<v-text-field
									label="Soru"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="faq.cfqQuestion.$model"
									:error-messages="cfqQuestionErrors(index)"
								></v-text-field>
							</v-col>
							<v-col cols="12" md="6">
								<v-text-field
									label="Cevap"
									outlined
									hide-details="auto"
									flat
									dense
									color="teal"
									v-model="faq.cfqAnswer.$model"
									:error-messages="cfqAnswerErrors(index)"
								></v-text-field>
							</v-col>
						</v-row>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn outlined color="red darken-4" depressed class="text-capitalize" @click="remove(index)">
							<v-icon left x-small v-text="'fas fa-trash'"></v-icon>
							sil
						</v-btn>
					</v-list-item-action>
				</v-list-item>
			</v-list>
			<div class="d-flex justify-start py-5">
				<v-btn
					:loading="savePreloader"
					color="teal"
					class="text-capitalize"
					:dark="!$v.cmsFaq.$invalid"
					@click="save"
					:disabled="$v.cmsFaq.$invalid"
				>
					Değişiklikleri Kaydet
				</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { FETCH_SITE_SETTINGS, UPDATE_FAQ } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import faqValidationMixin from '@/mixins/validations/backoffice/faqValidationMixin';

export default {
	name: 'Faq',
	mixins: [validationMixin, faqValidationMixin],
	data() {
		return {
			savePreloader: false,
			cmsFaq: [],
		};
	},

	computed: {
		...mapGetters(['settings']),
		initialized() {
			return this.cmsFaq !== null;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.fetchSiteSettings();
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchSiteSettings: FETCH_SITE_SETTINGS,
			updateFaq: UPDATE_FAQ,
		}),

		add() {
			this.cmsFaq.push({
				cfqQuestion: null,
				cfqAnswer: null,
			});
		},
		remove(index) {
			this.cmsFaq.splice(index, 1);
		},

		save() {
			this.savePreloader = true;
			this.updateFaq(this.cmsFaq)
				.then(() => {
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı!',
						text: 'Değişiklikler başarılı bir şekilde kaydedilmiştir.',
					});
				})
				.finally(() => {
					this.savePreloader = false;
				});
		},
	},
	watch: {
		'settings.company': {
			handler(value) {
				const company = this.$cloneDeep(value);
				this.cmsFaq = company.faq;
			},
			deep: true,
		},
	},
};
</script>

<style></style>
