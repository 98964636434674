/* eslint-disable no-unused-expressions */
import { required } from 'vuelidate/lib/validators';

const faqValidationMixin = {
	validations: {
		cmsFaq: {
			$each: {
				cfqQuestion: {
					required,
				},
				cfqAnswer: {
					required,
				},
			},
		},
	},
	methods: {
		// validation methods for this page
		cfqQuestionErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsFaq.$each[index].cfqQuestion;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir soru giriniz.');
			return errors;
		},
		cfqAnswerErrors(index) {
			const errors = [];
			const { $dirty, required } = this.$v.cmsFaq.$each[index].cfqAnswer;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir cevap giriniz.');
			return errors;
		},
	},
};

export default faqValidationMixin;
